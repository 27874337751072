<template>
  <div class="not-found">
    <h1>Looks like you haven't claimed your SmartMenu yet?</h1>
    <p>Reach out to: <strong>hello@foodcalc.com</strong> to get started today!</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}
</style>
